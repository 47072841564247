
import { computed, onMounted, onUpdated, ref } from "vue";
import { Form, Field, useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { types } from "sass";
import String = types.String;
import { useStore } from "vuex";
import TaxesSelect from "@/components/catalogs-select/TaxesSelect.vue";
import TaxesTypeSelect from "@/components/catalogs-select/TaxesTypeSelect.vue";

interface FormCatalog {
  id?: number;
  tax_id?: number;
  taxes_type_id?: number;
  percentage: string;
  start_date: string;
  end_date: string;
  billing_legend: string;
}

export default {
  name: "Taxes_detailForm",
  components: {
    TaxesTypeSelect,
    TaxesSelect,
    Form,
    Field,
  },
  props: {
    entityId: String,
    multiselect: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      id: undefined,
      tax_id: undefined,
      taxes_type_id: undefined,
      percentage: "",
      start_date: "",
      end_date: "",
      billing_legend: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const isCodeRequired = (value) => {
      if (!value) {
        return t("rcode");
      }
      return true;
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/taxes_detail/" + props.entityId).then(
          ({ data }) => {
            form.value = { ...data };
            store.commit("setLoadingStatus", false);
          }
        );
      } else {
        getTaxes();
        getTaxesType();
      }
      selectTaxes("");
    });

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (await ApiService.post("/api/taxes_detail", form.value))
          .data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put("/api/taxes_detail/" + props.entityId, form.value)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };
    const inputTaxes = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const inputTaxesType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const taxesData = computed(() => store.getters.Taxes);
    const selectTaxes = (query) => {
      if (query !== "") {
        inputTaxes.value.loading = true;
        setTimeout(() => {
          inputTaxes.value.loading = false;
          inputTaxes.value.options = inputTaxes.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputTaxes.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/taxes/lists`, {
              params: {
                per_page: 25,
                name: query,
              },
            }).then(({ data }) => {
              data.taxes.forEach((item) => {
                store.commit("addTaxes", item);
              });
              inputTaxes.value.list = taxesData.value;
              inputTaxes.value.options = data.taxes;
            });
          }
        }, 200);
      } else {
        inputTaxes.value.options = taxesData.value;
      }
    };

    const isTaxRequired = (value) => {
      if (props.required && !value) {
        return t("rtaxes");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "taxes",
      isTaxRequired
    );

    const getTaxes = () => {
      if (props.load && !taxesData.value.length) {
        store.commit("setLoading", false);
        ApiService.query(`/api/taxes/lists`, {
          params: {
            per_page: 25,
            name: "",
          },
        }).then(({ data }) => {
          store.commit("setTaxes", data.taxes);
          inputTaxes.value.list = data.taxes;
          inputTaxes.value.options = data.taxes;
        });
      } else {
        inputTaxes.value.list = taxesData.value;
        inputTaxes.value.options = taxesData.value;
      }
    };
    const getTaxesType = () => {
      store.commit("setLoading", false);
      ApiService.query(`/api/taxTypes/lists`, {
        params: {
          per_page: 25,
          name: "",
        },
      }).then(({ data }) => {
        inputTaxesType.value.list = data.taxes;
        inputTaxesType.value.options = data.taxes;
      });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      errorMessage,
      inputTaxes,
      inputTaxesType,
      form,
      onSumbit,
      isNameRequired,
      isCodeRequired,
      updateElement,
      createElement,
      selectTaxes,
      getTaxes,
      getTaxesType,
    };
  },
};
